import React, { useState } from "react"
import axios from "axios"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import "../layout.css"
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons"
import { Row, Form, Col } from "react-bootstrap"
import { CustomButton } from "../Buttons/parent"
import {
  Label,
  FormControl,
  ButtonSend,
  ButtonDiv,
  Break,
  FormCol,
  FlexFill,
} from "./ContactUsStyled"
import { JackTurnstile } from "../turnstile"

function FormContactUs({ props }) {
  const [isVerified, setIsVerified] = useState(false)
  const [done, setDone] = useState(false)
  const [inputForm, setInputForm] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
    codePhone: "",
  })

  function onChange(e) {
    let { name, value } = e.target
    setDone(false)
    const newInputForm = {
      ...inputForm,
      [name]: value,
    }
    setInputForm(newInputForm)
  }

  function onSubmit(e) {
    e.preventDefault()
    if (!isVerified) return
    const headers = {
      Authorization:
        "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjo0fQ.HzngeSUFSrGEeCk4lL7zHElSUhljNf_0PSK1uB5UWkg",
    }
    axios
      .post(
        "https://api.webform.transfez.com/api/v1/contacts/",
        {
          name: inputForm.name,
          email: inputForm.email,
          phone: `${inputForm.codePhone} ${inputForm.phone}`,
          message: inputForm.message,
        },
        { headers: headers }
      )
      .then(res => {
        setDone(true)
        setInputForm({
          name: "",
          email: "",
          phone: "",
          message: "",
        })
      })
      .catch(err => {
        console.log(err)
      })
  }

  const onChangePhone = (value, e) => {
    setInputForm({
      ...inputForm,
      codePhone: `(+${e.dialCode}) `,
      phone: value,
    })
  }

  const LabelCol = "col-5 col-md-3"
  const {
    Name1,
    Name2,
    Email1,
    Email2,
    Phone1,
    Phone2,
    Message1,
    Message2,
    Send,
    Success,
  } = props.Form
  return (
    <>
      <Form onSubmit={onSubmit} style={{ marginBottom: 0 }}>
        <Row>
          <Label className={LabelCol}>{Name1}</Label>
          <FormCol md={5}>
            <FormControl
              type="text"
              name="name"
              value={inputForm.name}
              placeholder={Name2}
              onChange={onChange}
              required
            />
          </FormCol>
          <Break />
          <Label className={LabelCol}>{Email1}</Label>
          <FormCol md={9}>
            <FormControl
              type="text"
              name="email"
              value={inputForm.email}
              placeholder={Email2}
              onChange={onChange}
              required
            />
          </FormCol>
          <Break />
          <Label className={LabelCol}>{Phone1}</Label>
          <FormCol md={9}>
            {/* <FormControl
              type="number"
              name="phone"
              value={inputForm.phone}
              placeholder={Phone2}
              onChange={onChange}
              required
            /> */}
            <PhoneInput
              country={"id"}
              value={inputForm.phone}
              onChange={onChangePhone}
              placeholder={Phone2}
              countryCodeEditable={false}
              onMouseEnter={() => setIsHover(true)}
              inputProps={{
                name: "phone",
                required: true,
              }}
              buttonClass="button-phone-input"
              buttonStyle={{
                backgroundColor: "#db64a0",
                borderRadius: "10px 0px 0px 10px",
                padding: "0px 30px 0px 20px",
              }}
              inputStyle={{
                fontFamily: "Typefez-reg",
                fontSize: "16px",
                color: "#db64a0",
                width: "100%",
                height: "46px",
                paddingLeft: "100px",
                backgroundColor: "transparent",
                borderRadius: "10px",
              }}
            />
          </FormCol>
          <Break />
          <Label className={LabelCol}>{Message1}</Label>
          <FormCol md={9}>
            <FormControl
              as="textarea"
              placeholder={Message2}
              name="message"
              value={inputForm.message}
              row={4}
              className="mb-3"
              style={{ fontFamily: "Typefez-bold" }}
              onChange={onChange}
              required
              height="100px"
            />
          </FormCol>
          <Break />
          {!isVerified && (
            <JackTurnstile onVerify={val => setIsVerified(val)} />
          )}
          <Label className={LabelCol} />
          <ButtonDiv md={9}>
            <CustomButton type="submit" padding="5px 25px">
              {Send}
            </CustomButton>
            <FlexFill>
              <small>
                {done ? (
                  <>
                    <FontAwesomeIcon icon={faCheckCircle} /> {Success}
                  </>
                ) : null}
              </small>
            </FlexFill>
          </ButtonDiv>
        </Row>
      </Form>
    </>
  )
}  

export default FormContactUs

const ButtonSubmit = styled.button`
  padding: 6px 35px;
  color: #ffd27c;
  background-color: #196e97;
  font-family: "GothamMedium";
  border: none;
  border-radius: 10px;
  transition: 200ms;
  -webkit-box-shadow: -1px 7px 5px -4px #a0a0a0;
  box-shadow: -1px 7px 5px -4px #a0a0a0;
  outline: none !important;
  margin-right: 10px;
  font-size: 0.875rem;
  :hover {
    background-color: #ffd27c;
    color: #196e97;
  }
`

const CustomPhoneInput = styled(PhoneInput)`
  border-radius: 10px !important;
  outline: none !important;
  .form-control {
    :hover,
    :focus,
    :active {
      outline: none !important;
      box-shadow: none !important;
      border-color: white !important;
    }
  }
  .flag-dropdown {
    /* width: 100px !important; */
    background-color: #db64a0 !important;
    border-radius: 10px 0px 0px 10px !important;
    :hover,
    :focus,
    :active {
      outline: none !important;
      box-shadow: none !important;
      background-color: #db64a0 !important;
    }
    .selected-flag {
      background-color: #db64a0 !important;
      padding-left: 25px !important;
      padding-right: 75px !important;
      border-radius: 10px !important;
      :hover {
        background-color: #db64a0 !important;
      }
      .flag {
        .up {
          margin: -3px 0px 0px 15px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 7px solid #fff;
          border-bottom: none;
        }
        .arrow {
          margin: -3px 0px 0px 15px;
          border-left: 5px solid transparent;
          border-right: 5px solid transparent;
          border-top: 7px solid #fff;
        }
      }
    }
  }
  :focus,
  :hover,
  :active {
    outline: none !important;
    box-shadow: none !important;
  }
`
