import styled from "styled-components"
import { Container, Col, Row, Form, Button } from "react-bootstrap"

export const ContainerStyled = styled(Container)`
  background-color: #333334;
  box-shadow: 0px 10px 30px rgba(15, 44, 87, 0.12);
  position: relative;
  padding: 100px 100px 150px 100px;
  border-radius: 10px 10px 0px 0px;
  overflow: hidden;
  @media (max-width: 576px) {
    padding: 50px;
  }
`

export const ContainerDiv = styled.div`
  margin-left: auto;
  margin-right: auto;
`

export const Label = styled.label`
  font-family: "Typefez-bold";
  color: #fff;
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  line-height: 1.5;
  position: relative;
`

export const ColFormLabel = styled(Col)`
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  line-height: 1.5;
`

export const FormControl = styled(Form.Control)`
  font-family: "Typefez-bold";
  border: 1px solid white !important;
  background-color: transparent !important;
  color: #da649f !important;
  border-radius: 10px !important;
  width: 100%;
  padding: 10px 15px !important;
  height: ${p => p.height || "auto !important"};
  outline: none !important;
  ::placeholder {
    color: rgba(218, 100, 159, 0.5) !important;
  }
  :focus,
  :hover,
  :active {
    outline: none !important;
    box-shadow: none !important;
    border: 1px solid white !important;
  }
`

export const FormCol = styled(Col)`
  margin-bottom: 1rem;
  display: flex;
`

export const ButtonDiv = styled(Col)`
  display: flex;
  /* flex-direction: column; */
  /* align-items: "center"; */
  /* @media (min-width: 768px) {
    flex-direction: row !important;
    margin-left: 16.666667%;
  } */
`

export const ButtonSend = styled(Button)`
  font-family: "GothamMedium";
  background-color: #196e97;
  border-color: #196e97;
  color: #ffd27c;
  padding-right: 3rem !important;
  padding-left: 3rem !important;
  margin-left: auto;
  margin-right: auto;
  line-height: 10px;
  height: 2.4rem;
  @media (min-width: 768px) {
    margin-right: 1rem !important;
  }
  @media (min-width: 768px) {
    margin-left: 0 !important;
  }
  @media (min-width: 768px) {
    margin-bottom: 0 !important;
  }
`

export const Break = styled.div`
  width: 100%;
`
export const FlexFill = styled.div`
  display: flex;
  line-height: 15px;
  height: 2.4rem;
  margin-left: 20px;
  > small {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.05em;
    color: white;
    font-family: "Typefez-reg";
  }
`

export const ImgBig = styled.img`
  position: absolute;
  min-width: 300px;
  top: -150px;
  right: -100px;
  @media (max-width: 992px) {
    display: none;
  }
`
