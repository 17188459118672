import styled from "styled-components"
import { Link } from "gatsby"
import { Button } from "react-bootstrap"

export const SectionTitleH3 = styled.h2`
  font-size: 40px;
  font-family: "Typefez-black";
  color: white;
  margin-bottom: 50px;
`

export const SectionDesc = styled.h3`
  font-family: "GothamMedium";
  color: #48a9b5 !important;
  text-align: center;
  margin-bottom: 3rem !important;
  font-size: 1rem;
`

export const ErrorDiv = styled.div`
  max-width: 800px;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  @media (min-width: 768px) {
    flex-direction: row;
  }
`

export const ErrorBody = styled.div`
  display: flex;
  color: white;
  background-color: #48a9b5;
  height: 100vh;
  justify-content: center;
  align-items: center;
`

export const ErrorTextDiv = styled.div`
  position: relative;
`

export const ErrorH1 = styled.h1`
  font-size: 3.5rem;
  font-family: "GothamMedium";
`

export const ErrorH5 = styled.h5`
  color: #196e97;
  font-family: "GothamMedium";
`
export const ErrorP = styled.p`
  color: white;
  font-family: "GothamLight";
`

export const ErrorButton = styled(Button)`
  color: #ffd27c;
  border-color: #196e97;
  background-color: #196e97;
  text-transform: uppercase;
  font-size: 0.875rem;
  line-height: 1.5;
  padding: 0.25rem 0.5rem;
  font-family: "GothamLight";
  font-weight: 600;
  cursor: pointer;
  @media (min-width: 900px) {
    position: absolute;
    top: 20px;
    right: -92px;
  }
  :hover {
    filter: brightness(0.8);
    color: #ffd27c;
    border-color: #196e97;
    background-color: #196e97;
  }
`
