import React, { useEffect, useState } from "react"
import Turnstile from "react-turnstile"

export const JackTurnstile = ({ onVerify }) => {
  const isProduction = process.env.GATSBY_LOCATION_NOW == "PRODUCTION"
  // const isProduction = true
  useEffect(() => {
    onVerify(!isProduction)
  }, [])

  if (!isProduction) return null

  return (
    <div style={{ width: "100%", textAlign: "center" }}>
      <Turnstile
        size="normal"
        sitekey="0x4AAAAAAANMlwaJnK_kBHqU"
        onVerify={() => onVerify(true)}
        theme="light"
      />
    </div>
  )
}
