import React from "react"
import "../components/layout.css"
import { useLocation } from "@reach/router"

// Import Component
import Footer from "../components/FooterComponents/Footer"
import HeaderContactUs from "../components/HeaderComponents/HeaderContactUs"
import ContactUsSection from "../components/ContactUs/ContactUs"

// Import Json
import { MainPageJson, PathJson, ContactUsJson } from "../components/location"
import Seo from "../components/SEOComponents/Seo"
import { NavigationBar } from "../components/NavigationBar/parent"

function CotactUs() {
  const MainPageContent = MainPageJson()
  const PathContent = PathJson()
  const ContactUsContent = ContactUsJson()
  const location = useLocation()
  const isEnglish = location.pathname.includes("/en")

  return (
    <div className="maxwidth">
      <Seo title="contact-us" />
      <NavigationBar />
      <HeaderContactUs
        props={MainPageContent.Header}
        propsLink={PathContent}
        propsData={ContactUsContent}
      />
      <ContactUsSection props={ContactUsContent} />
      <Footer props={MainPageContent.Footer} propsLink={PathContent} />
    </div>
  )
}

export default CotactUs
