import React from "react"
import FormContactUs from "./Form"
import { Col, Row } from "react-bootstrap"
import bigQuotes from "../../images/contact-us-big-quotes.png"
import {
  ContactUsStyled,
  ContainerStyled,
  ContainerDiv,
  ImgBig,
} from "./ContactUsStyled"
import { SectionTitleH3 } from "../StyledComponent"

function ContactUsSection({ props }) {
  const { Title, Desc } = props.Form
  return (
    <ContainerStyled>
      <SectionTitleH3>{Title}</SectionTitleH3>
      <Row>
        <Col lg={9}>
          <FormContactUs props={props} />
        </Col>
        <Col lg={3} style={{ position: "relative" }}>
          <ImgBig src={bigQuotes} alt="quotes" />
        </Col>
      </Row>
    </ContainerStyled>
  )
}

export default ContactUsSection
